export default [
    {
        path: '/all-items',
        name: 'all-items',
        component: () => import('@/views/pages/items/Index'),
    },
    {
        path: '/create-item',
        name: 'create-item',
        component: () => import('@/views/pages/items/Create'),
    },
    {
        path: '/update-item/:id',
        name: 'update-item',
        component: () => import('@/views/pages/items/Update'),
    },
]
