export default [
    {
        path: '/all-colors',
        name: 'all-colors',
        component: () => import('@/views/pages/colors/Index'),
    },
    {
        path: '/create-color',
        name: 'create-color',
        component: () => import('@/views/pages/colors/Create'),
    },
    {
        path: '/edit-color/:color_id',
        name: 'edit-color',
        component: () => import('@/views/pages/colors/Edit'),
    },
]
