import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import email from './routes/sellers routes/email'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import Pages from './routes/sellers routes/pages'
import UsersRoute from './routes/users routes/pages';
import ItemsRouter from './routes/items-routers/pages';
import ColorRouter from './routes/color-routers/pages';
import AdditionRouter from './routes/addition-routers/pages';
import CustomerRoute from './routes/customer-routers/pages';
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/', redirect: {name: 'all-offers'}},
        ...Pages,
        ...apps,
        ...dashboard,
        ...pages,
        ...UsersRoute,
        ...CustomerRoute,
        ...ItemsRouter,
        ...ColorRouter,
        ...AdditionRouter,
        ...chartsMaps,
        ...formsTable,
        ...uiElements,
        ...others,
        ...email,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({name: 'auth-login'})

        // If logged in => not authorized
        return next({name: 'misc-not-authorized'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
