export default [
    {
        path: '/all-users',
        name: 'all-users',
        component: () => import('@/views/pages/users/Index'),
    },
    {
        path: '/create-user',
        name: 'create-user',
        component: () => import('@/views/pages/users/Create'),
    },
    {
        path: '/update-user/:id',
        name: 'update-user',
        component: () => import('@/views/pages/users/Update'),
    },
]

