export default [
    {
        path: '/all-additions',
        name: 'all-additions',
        component: () => import('@/views/pages/additions/Index'),
    },
    {
        path: '/create-addition',
        name: 'create-addition',
        component: () => import('@/views/pages/additions/Create'),
    },
    {
        path: '/edit-addition/:addition_id',
        name: 'edit-addition',
        component: () => import('@/views/pages/additions/Edit'),
    },
]
