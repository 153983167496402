export default {
    namespaced: true,
    state: {
        table: null
    },
    mutations: {
        setTable (state, newValue) {
            state.table = newValue
        }
    },
    actions: {
        setTable ({ commit }, newValue) {
            commit('setTable', newValue)
        }
    }
}
