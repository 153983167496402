export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/pages/seller/Home'),
    },
    {
        path: '/all-offers',
        name: 'all-offers',
        component: () => import('@/views/pages/seller/Index'),
    },
    {
        path: '/all-offers-removed',
        name: 'all-offers-removed',
        component: () => import('@/views/pages/seller/IdexRemoved'),
    },
    {
        path: '/create-offer',
        name: 'create-offer',
        component: () => import('@/views/pages/seller/CreateOffer'),
    },
    {
        path: '/update-offer/:child_id/:parent_id',
        name: 'update-offer',
        component: () => import('@/views/pages/seller/UpdateOffer'),
    },
    {
        path: '/sales-tickets',
        name: 'sales-tickets',
        component: () => import('@/views/pages/seller/SalesTickets'),
    },
    {
        path: '/sales-tickets/:ticket_id',
        name: 'sales-ticket-details',
        component: () => import('@/views/pages/seller/SalesTickets'),
    },
    {
        path: '/all-projects',
        name: 'all-projects',
        component: () => import('@/views/pages/seller/Projects/Index'),
    },
    {
        path: '/create-project',
        name: 'create-project',
        component: () => import('@/views/pages/seller/Projects/Create'),
    },
    {
        path: '/update-project/:id',
        name: 'update-project',
        component: () => import('@/views/pages/seller/Projects/Update'),
    },

]
