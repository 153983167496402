export default [
  {
    path: "/offer-renew/:id",
    name: "renew",
    component: () => import("@/views/pages/seller/Renew"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
];
