import {$themeBreakpoints} from '@themeConfig'

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false,
        TableOfQuantityModalSubmitted: false,
        isLoading: false,
        initItemObj: {
            // indicator
            formItemTouched: false,
            id: undefined,
            parent_id: undefined,
            item_id: undefined,
            description_id: undefined,
            additional_option_ids: [],
            glass_type_id: [],
            width: '',
            height: '',
            meter_price: undefined,
            unit_price: 0,
            total_price: 0,
            quantity: 1,
            category_id: undefined,
            descriptionOptions: [],
            additional_options_cost: 0,
            glass_detail_id: [],
            reference_code: {},
            reference_code_id: null,
            sub_items: undefined,
            // indicator
            withGlassDetails: false,

            // text input
            other_glass_detail: undefined,

            // validation
            valueInBetween: undefined,
            widthError: undefined,
            heightError: undefined,

            // text input
            product_category_note: undefined,

            // indicator
            withProductCategoryNote: false,

            // text input
            other_glass_type: undefined,

            // indicator
            withCustomGlassTypeSelect: false,

            // select list
            localGlassTypeOptions: [],

            // hidden fields
            sand_blast: undefined,
            plan_location: undefined,
            order: 1,

        }
    },
    getters: {
        currentBreakPoint: state => {
            const {windowWidth} = state
            if (windowWidth >= $themeBreakpoints.xl) return 'xl'
            if (windowWidth >= $themeBreakpoints.lg) return 'lg'
            if (windowWidth >= $themeBreakpoints.md) return 'md'
            if (windowWidth >= $themeBreakpoints.sm) return 'sm'
            return 'xs'
        },
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
        },
        UPDATE_LOADER_STATE(state, val) {
            state.isLoading = val
        },
        UPDATE_TABLE_OF_QUANTITY_SUBMIT_BTN(state, val) {
            state.TableOfQuantityModalSubmitted = val
        },
    },
    actions: {},
}
