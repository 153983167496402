export default [
    {
        path: '/all-customers',
        name: 'all-customers',
        component: () => import('@/views/pages/customers/Index'),
    },
    {
        path: '/create-customer',
        name: 'create-customer',
        component: () => import('@/views/pages/customers/Create'),
    },
    {
        path: '/update-customer/:id',
        name: 'update-customer',
        component: () => import('@/views/pages/customers/Update'),
    },
]
